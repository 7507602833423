import React, { useState, useEffect } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import ProgressBar from 'react-bootstrap/ProgressBar'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";


function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
let stop = undefined;
let interval = undefined;
const App = () => {
  const [progression, setProgression] = useState(0);
  const [fake, setFake] = useState(0);
  const [display, setDisplay] = useState(0);
  const [data] = useState(require(`./games${window.location.pathname}.json`))
  const [unityContext] = useState(new UnityContext({
    loaderUrl: data["build"]+".loader.js",
    dataUrl:  data["build"]+".data.br",
    frameworkUrl:  data["build"]+".framework.js.br",
    codeUrl:  data["build"]+".wasm.br",
  }));
  
  useEffect(() => {
    unityContext.on("progress", function (p) {
      if(progression<p)
        setProgression(p*100);
    });
    stop = getRandomArbitrary(78, 87);
    interval = setInterval(() => {
      setFake((prev) => prev + 1);
    }, getRandomArbitrary(50,1000));    
}, []);
  useEffect(() => {
    if (fake >= stop) {
      clearInterval(interval);
    }
  }, [fake]);
  useEffect(() => {
    if (fake > progression) {
      setDisplay(fake);
    }
    else {
      setDisplay(progression);
    }      
  }, [fake, progression]);
  return (
    <div style={{width: "100%", height: "100%"}}>
    {progression<100&&<ProgressBar now={display}/>}
    <div style={{       
      width: data["size"]["width"],  
      height: data["size"]["height"],  
      position:"absolute",
      textAlign: "center",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      boxShadow: "0px 0px 31px 9px #0275d8",
      backgroundColor:  "#231f20",
      }} >
    <Unity unityContext={unityContext} style={{width:"100%", height:"100%"}}/>
  </div >
</div>
  )
}

export default App;
